import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const filterOut = keyframes`
  0% {
    filter: blur(0.25vw);
  }
  100% {
    filter: blur(0);
  }
`

const dashOffset = keyframes`
  0% {
    stroke-dashoffset: 101;
  }
  50% {
    stroke-dashoffset: 202;
  }
  100% {
    stroke-dashoffset: 303;
  }
`

const CoverAnimatedStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.backgroundDark};
  min-height: 100vh;
  padding: 25vh 0;
  z-index: 3;

  @media (max-width: ${breakpoints.screenLG}) {
    display: none;
  }

  .visual {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .shapes {
    margin-right: 2vw;
  }

  .logo {
    position: absolute;
    bottom: 0;
    opacity: 0;
    margin-right: 2.5vw;
    filter: blur(0.25vw);
    will-change: opacity, filter;
    animation: ${fadeIn} 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards,
      ${filterOut} 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 1.2s forwards;

    svg {
      width: 7.2vw;
      height: auto;
    }
  }

  .info {
    width: 54vw;
    padding-top: 4%;
    padding-left: 1.5vw;
  }

  .title {
    opacity: 0;
    will-change: opacity;
    animation: ${fadeIn} 1.2s cubic-bezier(0.7, 0, 0.3, 1) 3.2s forwards;
  }
`

const CoverDefaultStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.backgroundDark};
  height: 100vh;
  padding: 3% 3.5vw;

  @media (max-width: ${breakpoints.screenLG}) {
    flex-direction: column;
    padding: 4% 4vw 6%;
  }

  @media (max-width: ${breakpoints.screenSM}) {
    padding: 6% 5vw 8%;
    height: calc(var(--vh, 1vh) * 100);
  }

  .visual {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1vw;

    @media (max-width: ${breakpoints.screenLG}) {
      padding-right: 0;
    }
  }

  .shapes {
    opacity: 0;
    will-change: opacity;
    animation: ${fadeIn} 1s cubic-bezier(0.7, 0, 0.3, 1) 0s forwards;

    svg {
      width: 45vw;
      height: auto;

      @media (max-width: ${breakpoints.screenLG}) {
        width: 70vw;
      }
    }
  }

  .logo {
    display: flex;
    gap: 2vw;
    opacity: 0;
    filter: blur(0.25vw);
    will-change: opacity, filter;
    animation: ${fadeIn} 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards,
      ${filterOut} 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 1.2s forwards;

    @media (max-width: ${breakpoints.screenLG}) {
      flex-direction: column;
    }

    svg {
      width: 9vw;
      height: auto;

      @media (max-width: ${breakpoints.screenLG}) {
        width: 18vw;
      }

      @media (max-width: ${breakpoints.screenMD}) {
        width: 22vw;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        width: 32vw;
      }
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-top: auto;
    text-align: right;
    opacity: 0;
    will-change: opacity;
    animation: ${fadeIn} 0.8s cubic-bezier(0.7, 0, 0.3, 1) forwards;
    animation-delay: ${({ isAnimatedCover }) =>
      isAnimatedCover ? '3.8s' : '1s'};
  }

  .title {
    &.hide-md {
      display: block;

      @media (max-width: ${breakpoints.screenLG}) {
        display: none;
      }
    }

    &.hide-lg {
      display: none;

      @media (max-width: ${breakpoints.screenLG}) {
        display: block;
      }
    }
  }

  .scroll-down {
    width: 2.2vw;
    height: auto;

    @media (max-width: ${breakpoints.screenLG}) {
      width: 5vw;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      width: 6vw;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      width: 8vw;
    }
  }

  .scroll-progress {
    transform: rotate(-90deg);
    transform-origin: center;
    stroke-dasharray: 101;
    stroke-dashoffset: 101;
    will-change: stroke-dashoffset;
    animation: ${dashOffset} 10s linear 0.4s infinite reverse;
  }
`

export { CoverAnimatedStyled, CoverDefaultStyled }
