import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { cursor } from '../constants/ui'

import useMousePosition from '../hooks/useMousePosition'

import { Cursor, Pointer } from '../styles/CursorStyled'
import { BodyS } from '../styles/Typography'
import { theme } from '../constants/theme'

/**
 * A custom cursor with different states.
 * @param {ComponentProps} props
 * @param {Object} props.location
 * @returns {FunctionComponent}
 */
const CursorPosition = ({ location }) => {
  const theme = useTheme()
  const { x, y } = useMousePosition()

  const [className, setClassName] = useState(cursor.NONE)

  useEffect(() => {
    setClassName(cursor.NONE)
  }, [location.pathname])

  useEffect(() => {
    // Hovering
    const links = document.querySelectorAll('a')
    const buttons = document.querySelectorAll('button')

    const clickables = [...links, ...buttons]

    clickables.forEach((clickable) => {
      clickable?.addEventListener('mouseenter', () =>
        setClassName(cursor.HOVERING)
      )
      clickable?.addEventListener('mouseleave', () => setClassName(cursor.NONE))
    })

    // Over Video
    const video = document.querySelector('.video')

    video?.addEventListener('mouseenter', () => setClassName(cursor.VIDEO))
    video?.addEventListener('mouseleave', () => setClassName(cursor.NONE))
  }, [x, y, className])

  return (
    <Cursor
      style={{
        transform: `translate(${x}px, ${y}px)`,
      }}
    >
      <Pointer className={className}>
        <BodyS className="video-pointer" weight={700} color={theme.textLight}>
          Sound on
        </BodyS>
      </Pointer>
    </Cursor>
  )
}

export default CursorPosition
