import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const HeaderStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 3% 3.5vw;
  opacity: 1;
  transition: opacity 0.2s ease;
  z-index: ${({ isAnimatedCover }) => (isAnimatedCover ? 4 : 3)};
  pointer-events: none;

  @media (max-width: ${breakpoints.screenLG}) {
    padding: 4% 4vw 6%;
  }

  @media (max-width: ${breakpoints.screenSM}) {
    padding: 6% 5vw 8%;
  }

  .header-group {
    display: flex;
    justify-content: space-between;

    @media (max-width: ${breakpoints.screenLG}) {
      align-items: center;
    }
  }

  .logo-link {
    display: flex;
    align-items: flex-start;
    pointer-events: auto;
  }

  .logo {
    width: 2.4vw;
    height: auto;
    margin-top: -15%;
    opacity: 0;
    will-change: opacity;
    animation: ${fadeIn} 0.8s cubic-bezier(0.7, 0, 0.3, 1) forwards;
    animation-delay: ${({ isAnimatedCover }) =>
      isAnimatedCover ? '3.8s' : '1s'};

    @media (max-width: ${breakpoints.screenLG}) {
      width: 6.2vw;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      width: 9vw;
      margin-top: -20%;
    }
  }

  .theme-indicator {
    height: 0.8vw;
    width: 0.8vw;
    border: 0.1vw solid ${({ textColor }) => textColor};
    border-radius: 100%;
    margin-top: 0.5%;
    /* margin-left: 4vw; */
    margin-left: auto;
  }

  .menu-group {
    display: none;
    gap: 4.6vw;
    /* margin-left: auto; */
    width: 100%;
    opacity: 0;
    will-change: opacity;
    animation: ${fadeIn} 0.8s cubic-bezier(0.7, 0, 0.3, 1) forwards;
    animation-delay: ${({ isAnimatedCover }) =>
      isAnimatedCover ? '3.8s' : '1s'};
    pointer-events: auto;

    @media (max-width: ${breakpoints.screenLG}) {
      display: none;
    }
  }

  .menu-column {
    display: flex;
    flex-direction: column;
  }

  .menu-item {
    text-transform: uppercase;
  }

  .menu-btn {
    display: flex;
    margin-left: auto;
    padding-bottom: 2%;
    pointer-events: auto;

    @media (max-width: ${breakpoints.screenLG}) {
      display: block;
    }

    p {
      text-transform: uppercase;
    }
  }

  .menu-title {
    position: relative;
    height: 1.2vw;
    margin-bottom: 1.7vw;
    cursor: pointer;
    opacity: 0;
    will-change: opacity;
    animation: ${fadeIn} 0.8s cubic-bezier(0.7, 0, 0.3, 1) forwards;
    animation-delay: ${({ isAnimatedCover }) =>
      isAnimatedCover ? '3.8s' : '1s'};

    span {
      position: absolute;
      top: 0;
      right: 0;
      white-space: nowrap;
      opacity: 0;
      will-change: opacity;

      &.btn-menu.show {
        animation: ${fadeIn} 0.4s cubic-bezier(0.7, 0, 0.3, 1) forwards;
      }

      &.btn-close.show {
        animation: ${fadeIn} 0.4s cubic-bezier(0.7, 0, 0.3, 1) forwards;
      }
    }
  }

  .menu-item {
    margin-bottom: 1vw;
  }
`

const MobileMenuStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ?? theme.backgroundHighlight};
  height: 0;
  will-change: height;
  transition: height 0.7s cubic-bezier(0.52, 0.08, 0.18, 1);
  z-index: 3;
  overflow: hidden;

  @media (max-width: ${breakpoints.screenLG}) {
    display: flex;
  }

  &.active {
    height: 100%;
  }

  .header-group {
    display: flex;
    justify-content: space-between;
    padding: 3% 3.5vw;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 4% 4vw 6%;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 6% 5vw 8%;
    }
  }

  .logo-link {
    display: flex;
    align-items: flex-start;
  }

  .logo {
    width: 2.4vw;
    height: auto;
    margin-top: -15%;
    opacity: 0;
    will-change: opacity;
    animation: ${fadeIn} 0.8s cubic-bezier(0.7, 0, 0.3, 1) 0.2s forwards;

    @media (max-width: ${breakpoints.screenLG}) {
      width: 6.2vw;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      width: 9vw;
      margin-top: -20%;
    }
  }

  .menu-btn {
    margin-left: auto;
    padding-bottom: 2%;
  }

  .menu-title {
    position: relative;
    text-transform: uppercase;
    text-align: right;
    height: 1.2vw;
    margin-bottom: 1.7vw;
    cursor: pointer;

    span {
      position: absolute;
      top: 0;
      right: 0;
      white-space: nowrap;
      opacity: 0;
      will-change: opacity;

      &.btn-menu.show {
        animation: ${fadeIn} 0.4s cubic-bezier(0.7, 0, 0.3, 1) forwards;
      }

      &.btn-close.show {
        animation: ${fadeIn} 0.4s cubic-bezier(0.7, 0, 0.3, 1) forwards;
      }
    }
  }

  .menu-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: 8% 4vw;

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 14% 5vw;
    }

    &.open {
      .menu-item {
        opacity: 1;
        transform: translateY(0);
        will-change: opacity, transform;
        transition: transform 0.8s cubic-bezier(0.22, 0.94, 0.44, 1),
          opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);

        &.item-1 {
          transition-delay: 0.4s;
        }

        &.item-2 {
          transition-delay: 0.5s;
        }

        &.item-3 {
          transition-delay: 0.6s;
        }

        &.item-4 {
          transition-delay: 0.7s;
        }

        &.item-5 {
          transition-delay: 0.8s;
        }

        &.item-6 {
          transition-delay: 0.9s;
        }
      }
    }

    &.close {
      .menu-item {
        opacity: 0;
        transform: translateY(160%);
        will-change: opacity, transform;
        transition: transform 0.4s cubic-bezier(0.22, 0.94, 0.44, 1),
          opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

        &.item-1,
        &.item-2,
        &.item-3,
        &.item-4,
        &.item-5,
        &.item-6 {
          transition-delay: 0s;
        }
      }
    }
  }

  .menu-item {
    position: relative;
    line-height: 1;
    margin-bottom: 20px;
  }
`

export { HeaderStyled, MobileMenuStyled }
