import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  to {
    opacity: 1;
  }
`

const Cursor = styled.div`
  position: fixed;
  pointer-events: none;
  opacity: 0;
  will-change: transform, opacity;
  transition: transform 0.2s ease-out;
  /* animation: ${fadeIn} 0.6s linear 3.8s forwards; //when cover is animating */
  animation: ${fadeIn} 0.6s linear 0.8s forwards;
  z-index: 10;

  @media (max-width: ${breakpoints.screenLG}) {
    display: none;
  }
`

const Pointer = styled.div`
  position: absolute;
  width: 0.8vw;
  height: 0.8vw;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.cursorBackground};
  border-radius: 100%;
  opacity: 1;
  will-change: opacity, width, height;
  transition: width ease 0.3s, height ease 0.3s, opacity ease 0.5s;

  @media (max-width: ${breakpoints.screenLG}) {
    display: none;
  }

  p {
    display: none;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &.hovering {
    width: 5vw;
    height: 5vw;
    opacity: 0.3 !important;
  }

  &.video {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10vw;
    height: 10vw;
    background-color: ${({ theme }) => theme.cursorVideoBackground};
    overflow: hidden;

    p {
      display: block;
    }
  }
`

export { Cursor, Pointer }
