import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { useTheme } from 'styled-components'

import { CookiesStyled } from '../styles/CookiesStyled'
import { BodyM } from '../styles/Typography'

import Plus from './icons/Plus'

/**
 * The Cookie banner.
 * @param {ComponentProps} props
 * @returns {FunctionComponent}
 */
const Cookies = () => {
  const theme = useTheme()

  return (
    <CookiesStyled>
      <CookieConsent
        buttonText={<Plus className="icon-close" color={theme.textLight} />}
        buttonClasses="btn"
        contentClasses="content"
        cookieName="gatsby-gdpr-google-analytics"
      >
        <BodyM color={theme.textLight}>
          We use cookies. Read more in our{' '}
          <a href="/privacy-policy" className="link">
            Privacy Policy
          </a>
          .
        </BodyM>
      </CookieConsent>
    </CookiesStyled>
  )
}

export default Cookies
