import React from 'react'

import { theme } from '../../constants/theme'

const ButikLogo = ({ color = theme.textLight, className }) => (
  <svg
    width="193"
    height="81"
    viewBox="0 0 193 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0 60.4677V0.847662H8.87868V23.0014H9.41319C9.92789 22.0699 10.6703 20.9927 11.6403 19.7701C12.6103 18.5474 13.9565 17.48 15.6787 16.5678C17.401 15.6362 19.6776 15.1705 22.5085 15.1705C26.1906 15.1705 29.4768 16.0826 32.3671 17.9069C35.2574 19.7312 37.5241 22.361 39.1672 25.7961C40.8301 29.2313 41.6615 33.3651 41.6615 38.1976C41.6615 43.0301 40.84 47.1735 39.1969 50.6281C37.5538 54.0632 35.297 56.7124 32.4265 58.5755C29.556 60.4192 26.2797 61.3411 22.5976 61.3411C19.8261 61.3411 17.5594 60.885 15.7975 59.9729C14.0554 59.0607 12.6895 57.9933 11.6997 56.7706C10.7098 55.5479 9.94769 54.4611 9.41319 53.5101H8.67082V60.4677H0ZM8.70052 38.1103C8.70052 41.2543 9.16573 44.0101 10.0962 46.3778C11.0266 48.7456 12.3727 50.599 14.1346 51.9381C15.8965 53.2578 18.0543 53.9177 20.608 53.9177C23.2608 53.9177 25.478 53.2287 27.2596 51.8508C29.0413 50.4534 30.3875 48.5612 31.2981 46.1741C32.2285 43.7869 32.6937 41.099 32.6937 38.1103C32.6937 35.1603 32.2384 32.5112 31.3278 30.1628C30.437 27.8145 29.0908 25.9611 27.2893 24.6026C25.5077 23.244 23.2806 22.5648 20.608 22.5648C18.0345 22.5648 15.8569 23.2149 14.0752 24.5152C12.3134 25.8155 10.9771 27.6302 10.0665 29.9591C9.15583 32.288 8.70052 35.005 8.70052 38.1103Z"
      fill={color}
    />
    <path
      d="M80.5243 41.9238V15.7527H89.4327V60.4677H80.7025V52.7241H80.2274C79.1782 55.1113 77.4955 57.1005 75.1793 58.692C72.8829 60.264 70.0223 61.05 66.5975 61.05C63.6677 61.05 61.0743 60.4192 58.8176 59.1577C56.5806 57.8768 54.8187 55.9846 53.5319 53.481C52.265 50.9774 51.6315 47.8819 51.6315 44.1945V15.7527H60.5102V43.1465C60.5102 46.1935 61.3713 48.6194 63.0936 50.4243C64.8159 52.2292 67.0529 53.1317 69.8046 53.1317C71.4675 53.1317 73.1205 52.7241 74.7636 51.909C76.4265 51.0939 77.8023 49.8615 78.8911 48.2119C79.9997 46.5622 80.5441 44.4662 80.5243 41.9238Z"
      fill={color}
    />
    <path
      d="M122.654 15.7527V22.7394H97.7397V15.7527H122.654ZM104.421 5.0397H113.3V47.3385C113.3 49.027 113.557 50.2982 114.072 51.1521C114.586 51.9866 115.25 52.5592 116.061 52.8697C116.893 53.1608 117.793 53.3063 118.764 53.3063C119.476 53.3063 120.1 53.2578 120.634 53.1608C121.169 53.0637 121.585 52.9861 121.881 52.9279L123.485 60.1184C122.97 60.3125 122.238 60.5066 121.288 60.7006C120.337 60.9141 119.15 61.0306 117.724 61.05C115.388 61.0888 113.211 60.6812 111.191 59.8273C109.172 58.9734 107.539 57.6536 106.292 55.8682C105.045 54.0827 104.421 51.8411 104.421 49.1434V5.0397Z"
      fill={color}
    />
    <path
      d="M132.475 60.4677V15.7527H141.354V60.4677H132.475ZM140.908 0.00107218V0.00342914C140.908 -0.00251327 140.908 0.00107218 140.908 0.00107218Z"
      fill={color}
    />
    <path
      d="M161.464 45.3007L161.405 34.6751H162.949L181.122 15.7527H191.753L171.026 37.2951H169.63L161.464 45.3007ZM153.298 60.4677V0.847662H162.177V60.4677H153.298ZM182.102 60.4677L165.77 39.2165L171.887 33.1322L193 60.4677H182.102Z"
      fill={color}
    />
    <circle cx="137.006" cy="74.8258" r="6.00626" fill={color} />
  </svg>
)

export default ButikLogo
