import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  to {
    opacity: 1;
  }
`

const CookiesStyled = styled.div`
  .CookieConsent {
    bottom: 4% !important;
    left: unset !important;
    right: 3.5vw !important;
    display: flex !important;
    align-items: center !important;
    justify-content: unset !important;
    flex-wrap: unset !important;
    gap: 1.5vw;
    background: ${({ theme }) => theme.cookiesBannerBackground} !important;
    color: unset !important;
    border-radius: 10vw;
    width: auto !important;
    padding: 1.5% 2vw;
    opacity: 0;
    will-change: opacity;
    animation: ${fadeIn} 0.8s cubic-bezier(0.7, 0, 0.3, 1) forwards;
    animation-delay: ${({ isAnimatedCover }) =>
      isAnimatedCover ? '3.8s' : '1s'};

    @media (max-width: ${breakpoints.screenLG}) {
      bottom: 3.5% !important;
      gap: 2vw;
      padding: 3% 4vw;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      bottom: 40px !important;
      right: 20px !important;
      gap: 20px;
      border-radius: 50px;
      padding: 20px 30px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      bottom: 30px !important;
      left: 15px !important;
      right: 15px !important;
      padding: 20px 30px 20px 40px;
    }
  }

  .content {
    margin: 0 !important;
    flex: 1 !important;
  }

  .btn {
    display: flex;
    color: transparent !important;
    background: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .link {
    text-decoration: underline;
  }

  .icon-close {
    width: 1.6vw;
    height: auto;
    transform: rotate(45deg);

    @media (max-width: ${breakpoints.screenLG}) {
      width: 3vw;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      width: 30px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      width: 35px;
    }
  }
`

export { CookiesStyled }
