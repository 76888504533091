import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'

import { blackTheme } from '../constants/theme'

import GlobalStyles from '../styles/GlobalStyles'

import Cursor from './Cursor'
import Cookies from './Cookies'
import CoverAnimated from './CoverAnimated'

/**
 * A page layout including all global styles and theme provider.
 * Used as a wrapper in gatsby-browser and gatsby-ssr.
 * @param {ComponentProps} props
 * @param {ChildNode} props.children
 * @param {Object} props.location
 * @returns {StatelessComponent}
 */
const Page = ({ children, location }) => {
  const [isDesktop, setIsDesktop] = useState(true)

  useEffect(() => {
    setIsDesktop(window.innerWidth > 992)
  }, [isDesktop])

  const theme = blackTheme

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        {isDesktop && (
          <>
            <Cursor location={location} />
            {/* <CoverAnimated location={location} /> */}
          </>
        )}
        {children}
        <Cookies />
      </ThemeProvider>
    </>
  )
}

export default Page
