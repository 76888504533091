import React from 'react'

import { theme } from '../../constants/theme'

const ButikLogoSmall = ({ color = theme.textLight, className }) => (
  <svg
    width="42"
    height="45"
    viewBox="0 0 42 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.3978 7.01739C39.1741 7.01739 40.6141 5.57741 40.6141 3.80109C40.6141 2.02477 39.1741 0.584783 37.3978 0.584783C35.6215 0.584783 34.1815 2.02477 34.1815 3.80109C34.1815 5.57741 35.6215 7.01739 37.3978 7.01739ZM37.3978 7.60218C39.4971 7.60218 41.1989 5.90037 41.1989 3.80109C41.1989 1.70181 39.4971 0 37.3978 0C35.2985 0 33.5967 1.70181 33.5967 3.80109C33.5967 5.90037 35.2985 7.60218 37.3978 7.60218Z"
      fill={color}
    />
    <circle cx="18.6989" cy="26.301" r="18.6989" fill={color} />
  </svg>
)

export default ButikLogoSmall
