import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const Headline = styled.h1`
  font-size: 14vw; // 210
  line-height: 0.9;
  letter-spacing: -0.1em;
  font-weight: ${({ weight }) => weight ?? 400};
  color: ${({ theme, color }) => color ?? theme.textPrimary};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 70px;
  }

  @media (max-width: ${breakpoints.screenSM}) {
    font-size: 50px;
  }
`

const TitleL = styled.h2`
  font-size: 7.2vw; // 110
  line-height: 1;
  letter-spacing: -0.1em;
  font-weight: ${({ weight }) => weight ?? 400};
  color: ${({ theme, color }) => color ?? theme.textPrimary};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 65px;
  }

  @media (max-width: ${breakpoints.screenSM}) {
    font-size: 50px;
  }
`

const TitleM = styled.h3`
  font-size: 5vw; // 76
  line-height: 1;
  letter-spacing: -0.06em;
  font-weight: ${({ weight }) => weight ?? 400};
  color: ${({ theme, color }) => color ?? theme.textPrimary};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 65px;
  }

  @media (max-width: ${breakpoints.screenSM}) {
    font-size: 50px;
  }
`

const TitleS = styled.h4`
  font-size: 4.2vw; // 64
  line-height: 1.2;
  letter-spacing: -0.05em;
  font-weight: ${({ weight }) => weight ?? 400};
  color: ${({ theme, color }) => color ?? theme.textPrimary};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 65px;
  }

  @media (max-width: ${breakpoints.screenSM}) {
    font-size: 45px;
  }
`

const BodyL = styled.p`
  font-size: 2vw; // 30
  line-height: 1.4;
  letter-spacing: -0.05em;
  font-weight: ${({ weight }) => weight ?? 400};
  color: ${({ theme, color }) => color ?? theme.textPrimary};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 3.6vw;
  }

  @media (max-width: ${breakpoints.screenSM}) {
    font-size: 6.2vw;
  }
`

const BodyM = styled.p`
  font-size: 1.05vw; //16
  line-height: 1.6;
  letter-spacing: -0.02em;
  font-weight: ${({ weight }) => weight ?? 400};
  color: ${({ theme, color }) => color ?? theme.textPrimary};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 20px;
  }

  @media (max-width: ${breakpoints.screenSM}) {
    font-size: 16px;
  }
`

const BodyS = styled.p`
  font-size: 0.8vw; //12
  line-height: 1.5;
  font-weight: ${({ weight }) => weight ?? 400};
  color: ${({ theme, color }) => color ?? theme.textPrimary};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 16px;
  }

  @media (max-width: ${breakpoints.screenSM}) {
    font-size: 12px;
  }
`

export { Headline, TitleL, TitleM, TitleS, BodyL, BodyM, BodyS }
