export const theme = {
  backgroundPrimary: '#fff',
  backgroundDark: '#0b0b0b',
  textPrimary: '#0b0b0b',
  textLight: '#fff',
}

const colors = {
  white: '#fff',
  black: '#0b0b0b',
  grey: '#292929',
  blue: '#b9d0ec',
  green_500: '#014837',
  green_400: '#00CD9A',
  orange: '#e9541e',
  pink: '#ca5ae8',
}

export const blackTheme = {
  // background
  backgroundPrimary: colors.white,
  backgroundDark: colors.black,
  backgroundHighlight: colors.green_500,
  // text
  textPrimary: colors.black,
  textLight: colors.white,
  textHighlight: colors.green_400,
  // cursor
  cursorBackground: colors.orange,
  cursorVideoBackground: colors.green_400,
  // logo
  logoBackground: colors.pink,
  // cookies
  cookiesBannerBackground: colors.grey,
  // lineup
  menuBackground: colors.orange,
  menuLogo: colors.green_400,
}

export const blueTheme = {
  // background
  backgroundPrimary: colors.white,
  backgroundDark: colors.blue,
  backgroundHighlight: colors.orange,
  // text
  textPrimary: colors.black,
  textLight: colors.white,
  textHighlight: colors.orange,
  // cursor
  cursorBackground: colors.orange,
  cursorVideoBackground: colors.white,
  // logo
  logoBackground: colors.pink,
}
