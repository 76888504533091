import styled, { keyframes } from 'styled-components'

import { theme } from '../constants/theme'
import breakpoints from '../constants/breakpoints'

const dashOffset = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const fill = keyframes`
  to {
    fill: ${theme.textLight};
  }
`

const fillZanzibar = keyframes`
  to {
    fill: ${theme.textPrimary};
  }
`

const ButikShapesStyled = styled.div`
  svg {
    width: 54vw;
    height: auto;

    @media (max-width: ${breakpoints.screenLG}) {
      width: 100%;
    }
  }

  .triangle {
    stroke-dasharray: 303;
    stroke-dashoffset: 303;
  }

  .circle {
    stroke-dasharray: 548;
    stroke-dashoffset: 548;
    will-change: stroke-dashoffset;
    animation: ${dashOffset} 1.6s cubic-bezier(0.7, 0, 0.3, 1) 2.4s forwards;
  }

  .circle-small {
    stroke-dasharray: 111;
    stroke-dashoffset: 111;
  }

  .outline {
    will-change: stroke-dashoffset;
    animation: ${dashOffset} 1.8s cubic-bezier(0.7, 0, 0.3, 1) 1.4s forwards;
  }

  .solid {
    fill: transparent;
    will-change: stroke-dashoffset;
    animation: ${dashOffset} 1.8s cubic-bezier(0.7, 0, 0.3, 1) 1.4s forwards,
      ${fill} 1.4s cubic-bezier(0.7, 0, 0.3, 1) 3.2s forwards;
  }
`

const ButikShapesZanzibarStyled = styled.div`
  svg {
    width: 59vw;
    height: auto;

    @media (max-width: ${breakpoints.screenLG}) {
      width: 800px;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      width: 600px;
    }

    @media (max-width: ${breakpoints.screenXS}) {
      width: 420px;
    }
  }

  .triangle {
    stroke-dasharray: 303;
    stroke-dashoffset: 303;
  }

  .circle-small {
    stroke-dasharray: 111;
    stroke-dashoffset: 111;
  }

  .outline {
    will-change: stroke-dashoffset;
    animation: ${dashOffset} 1.8s cubic-bezier(0.7, 0, 0.3, 1) forwards;
  }

  .solid {
    fill: transparent;
    will-change: stroke-dashoffset;
    animation: ${dashOffset} 1.8s cubic-bezier(0.7, 0, 0.3, 1) forwards,
      ${fillZanzibar} 1s cubic-bezier(0.7, 0, 0.3, 1) 1.4s forwards;
  }
`

export { ButikShapesStyled, ButikShapesZanzibarStyled }
